<template>

    <el-container style="height: 100%">
      <el-header style="display:flex;justify-content:space-between">
        <span style="font-size: x-large;font-family: Microsoft YaHei;">后台管理系统</span>
        <div>
          <el-button  type="primary" @click="logout()">
            退出
          </el-button>
        </div>

      </el-header>
      <el-container >
        <el-aside width="200px" style="background-color: rgb(238, 241, 246)">
          <el-menu :router="true" :default-openeds="openeds">
            <el-submenu index="1">
              <template slot="title"><i class="el-icon-document"></i>资料管理</template>
              <el-menu-item-group>
                <el-menu-item index="/essay">文章管理</el-menu-item>
                <el-menu-item index="/homePicture">首页图片管理</el-menu-item>
                <el-menu-item index="/homeShow">首页视频管理</el-menu-item>
                <el-menu-item index="museumMap">馆内地图管理</el-menu-item>
                <el-menu-item index="/open">开放时间管理</el-menu-item>
                <el-menu-item index="museumNotice">馆内须知管理</el-menu-item>
                <el-menu-item index="/adventure">漫游管理</el-menu-item>
                <el-menu-item index="/goods">文创管理</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="2">
              <template slot="title"><i class="el-icon-menu"></i>系统管理</template>
              <el-menu-item-group>
                <el-menu-item index="2-1">管理员管理</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title"><i class="el-icon-setting"></i>日志管理</template>
              <el-menu-item-group>
                <el-menu-item index="3-1">系统日志</el-menu-item>
                <el-menu-item index="3-2">登录统计</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </el-aside>
        <el-main>
        <router-view></router-view>
      </el-main>
      </el-container>

    </el-container>




</template>


<style>

.el-header {
  background-color: #B3C0D1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
</style>

<script>
export default {
  data() {

    return {
      openeds: ['1'],
    }
  },
  created() {
    this.$router.push('/essay')
  },
  methods:{
    logout(){
      //删除所有保存的数据
      window.sessionStorage.clear()
      //跳转登录页面
      this.$router.push('/login')
    }
  }
};

</script>