<template>
  <el-card class="box-card">
  <form class="layui-form" action="https://background.lanternmeta.com/api/Homeshow/upload" method="post" enctype="multipart/form-data">
    <div class="layui-form-item">
      <label class="layui-form-label">上传视频：</label>
      <div class="layui-input-block">
        <button v-on:click="uploadVideo" type="button" style="padding:10px 75px;font-size:16px;" class="el-button el-button--primary el-button--small">
          <i class="el-icon-upload el-icon--left"></i>点击上传
        </button>
      </div>
      <ul class="el-upload-list el-upload-list--picture">
        <li class="el-upload-list__item" v-for="(item, index) in uploadVideoList">
            <video :src="item.url" style="height:300px;width: 300px" controls></video>
          <font color="blue">{{item.name}}</font>
        </li>
      </ul>
    </div>
    <div class="layui-form-item" >
      <label class="layui-form-label">展示介绍：</label>
      <div class="layui-input-block" >
        <div style="display: none;">
          <input type="text" name="introduce" v-model="formData.introduce"/>
        </div>
        <quill-editor v-model="formData.introduce"></quill-editor>
      </div>
    </div>
    <input id="takevideo" style="display:none" type="file" name="video[]" multiple="multiple" accept="video/*" @change="batchUploadFilesChange">
    <div class="layui-word-aux">
      <ul>
        <li>上传必看：</li>
        <li>能上传所有类型的视频，但大小不能超过50MB；</li>
        <li>选择视频并且填写完介绍才能提交</li>
      </ul>
      <div class="layui-form-item " >
        <label class="layui-form-label"></label>
        <div class="layui-input-block">
          <button class="layui-btn el-button el-button--primary el-button--small" type="submit" style="padding:10px 75px;font-size:16px;"@click="submitVideo()">立即提交</button>
        </div>
      </div>
    </div>
  </form>

  <el-table
      :data="tableData"
      style="table-layout: fixed; word-break: break-all; word-wrap: break-word;" width="100%">
    <el-table-column
        prop="home_show_id"
        label="展示ID"
        min-width="10%">
    </el-table-column>
    <el-table-column
        prop="home_show_introduce"
        label="展示介绍"
        min-width="10%">
      <template slot-scope="scope">
        <div v-html="scope.row.home_show_introduce"></div>
      </template>
    </el-table-column>
    <el-table-column
        prop="home_show_video"
        label="访问地址"
        min-width="40%">
    </el-table-column>
    <el-table-column prop="home_show_video" label="视频" min-width="50%" >
      <!-- 图片的显示 -->
      <template   slot-scope="scope">
        <video :src="scope.row.home_show_video" style="height:300px;width: 300px" controls></video>
      </template>
    </el-table-column>
  </el-table>
  </el-card>
</template>

<script>

export default {
  data() {
    return {
      uploadVideoList: [], //批量上传视频集合
      tableData: [],
      formData: { // 表单数据对象
        introduce: '',
        video: []
      },
      editorOptions: {
        // 其他配置选项...
        name: 'introduce',
      },

    }
  },
  created() {
    this.getList()
  },
  methods:{
    getContent() {
      console.log(this.formData.introduce); // 获取控件内容
    },
    async getList(){
      const {data:res} = await this.$ajax.get('https://background.lanternmeta.com/api/Homeshow/index',{params:this.param})
      this.tableData =res
    },
    uploadVideo(){
      var takeVideo = document.getElementById('takevideo');
      takeVideo.click();
    },
    batchUploadFilesChange(e){
      var that = this
      var files=e.target.files
      for( var i in files) {
        const file=files[i]
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function(e) {
          var item = {
            name: file['name'],
            url: this.result,
          }
          //添加到集合
          that.uploadVideoList.push(item);
        }
      }
    },
    async submitVideo(){
      // 将文本数据和视频数据合并到表单数据对象中
      for(var i in this.uploadVideoList){
        this.formData.video.push(this.uploadVideoList[i]);
      }
      // 发送表单数据到后端处理
      const {data:res} = await this.$ajax.post('https://background.lanternmeta.com/api/Homeshow/upload', this.formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      // 提交完成后清空列表
      this.uploadVideoList = [];
      this.formData.introduce = '';
      this.formData.video = [];
    }
  }
}
</script>
<style scoped>
.el-pagination{
  margin-top: 1%;
  text-align: center;
}

.el-upload-list--picture{
  min-height:200px;
  max-height:400px;
  overflow:auto;
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  padding:10px;
  margin-top:10px;
}
/*滚动条样式*/
.el-upload-list--picture::-webkit-scrollbar {
  width: 3px;
}
.el-upload-list--picture::-webkit-scrollbar-track {
  background-color:#FCF8E3;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius:2em;
}
.el-upload-list--picture::-webkit-scrollbar-thumb {
  background-color:skyblue;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius:2em;
}
.el-upload-list--picture .el-upload-list__item{height:auto;}
.el-upload-list--picture .el-upload-list__item-thumbnail{float:none;width:50px;height:50px;}
.el-upload-input{width:100px;border-radius:5px;text-indent:5px;}
</style>