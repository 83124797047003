<template>


  <div class="login-page">
    <el-card class="box-card">
      <div slot="header" class="clearfix"style="text-align: center">
        <span class="login-title">🔐后台管理系统</span>
      </div>
      <div class="login-form">
        <el-form ref="form" :model="LoginForm" label-width="80px">
          <el-form-item  label="账号">
            <el-input v-model="LoginForm.username"type="text"auto-complete="off" placeholder="请输入用户名">
              <template slot="prepend"><i style="font-size:20px" class="el-icon-user"></i></template>
            </el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input type="password" v-model="LoginForm.password" auto-complete="off" placeholder="请输入密码">
              <template slot="prepend"><i style="font-size:20px" class="el-icon-key"></i></template>
            </el-input>

          </el-form-item>

          <el-form-item style="text-align: center;width: 80%">
            <el-button type="primary" @click="login()"style="">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data(){
    return{
      LoginForm:{
        username:'',
        password:''
      }
    }
  },
    created() {
        //this.login()
    },
  methods:{
      async login(){
        //跨域测试,传输登录数据
        //要记得加上 await,await 是在等待一个 async 函数完成
       const{data:res} = await this.$ajax.post('https://background.lanternmeta.com/api/Login/login',this.LoginForm)
        //console.log(res)

        //登录成功以后，提示信息：message组件
        if(res.code == 1){
          this.$msg.success(res.msg)
          //存入session
          window.sessionStorage.setItem('token',res.token)
          //跳转到后台首页
          this.$router.push('/')

        }else{
          this.$msg.error(res.msg)
        }


      }
  }
}
</script>

<style scoped>
.login-page{
  background-image: linear-gradient(180deg, #2af598 0%, #009efd 100%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-title{
  font-size: 20px;
}

.box-card {
  width: 375px;
}

</style>