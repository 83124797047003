
<template>
  <!--    表格-->
  <el-card class="box-card">

    <el-table
        :data="tableData"
        style="table-layout: fixed; word-break: break-all; word-wrap: break-word;" width="100%">
      <el-table-column
          prop="museum_notice"
          label="馆内须知">
        <template slot-scope="scope">
          <div v-html="scope.row.museum_notice"></div>
        </template>
      </el-table-column>
      <el-table-column
          label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="small"@click="edit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--编辑表单-->
    <el-dialog
        title="编辑表单"
        :visible.sync="editDialogVisible"
        width="50%"
    >
      <!--        <span>这是一段信息</span>-->
      <el-form :model="editform" label-width="80px">
        <el-form-item label="馆内须知">
          <quill-editor  v-model="editform.museum_notice">
          </quill-editor>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editSave()">确 定</el-button>
        </span>
    </el-dialog>
  </el-card>

</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      total:0,
      editDialogVisible : false,
      editform:{
        id:'',
        museum_notice:'',
          },
    }
  },
  created() {
    //created:在模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图。
    this.getList()
  },
  methods:{
    edit(info){
      this.editDialogVisible =true
      this.editform.id=info.id
      this.editform.museum_notice=info.museum_notice
    },
    async editSave(){
      // 关闭弹窗
      this.editDialogVisible=false
      // 提交数据
      const{data:res} = await this.$ajax.post('https://background.lanternmeta.com/api/Museumnotice/edit',this.editform)
      //弹出提示
      if(res.code==1){
        this.$msg.success(res.msg)
        //页面刷新
        this.getList()
        this.editform.id=''
        this.editform.museum_notice=''
      }else {
        this.$msg.error(res.msg)
      }
    },
    //获取数据库开放表数据
    async getList(){
      const{data:res} = await this.$ajax.get('https://background.lanternmeta.com/api/Museumnotice/index',{params:this.param})
      this.tableData =res
    }
  },
}
</script>

<style scoped>
.el-pagination{
  margin-top: 1%;
  text-align: center;
}

</style>