import Vue from 'vue'
import { Button,Form,FormItem,Input,Message,Container,Header,Aside,Main,Menu,MenuItem,MenuItemGroup,Submenu,Breadcrumb,BreadcrumbItem,Table,TableColumn,Card,Pagination,Dialog,Radio,Upload,Popover} from 'element-ui'

Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Submenu)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Card)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Radio)
Vue.use(Upload)
Vue.use(Popover)

Vue.prototype.$msg = Message