<template>

  <!--    表格-->
  <el-card class="box-card">
    <form class="layui-form" action="https://background.lanternmeta.com/api/MuseumMap/upload" method="post" enctype="multipart/form-data">
      <div class="layui-form-item">
        <label class="layui-form-label">上传图片：</label>
        <div class="layui-input-block">
          <button v-on:click="uploadImage" type="button" style="padding:10px 75px;font-size:16px;" class="el-button el-button--primary el-button--small">
            <i class="el-icon-upload el-icon--left"></i>点击上传
          </button>

        </div>
        <ul class="el-upload-list el-upload-list--picture">
          <li class="el-upload-list__item" v-for="(item, index) in uploadImgageList">
            <el-popover placement="left" trigger="hover">
              <img :src="item.url" style="height:300px;" />
              <img :src="item.url" slot="reference" class="el-upload-list__item-thumbnail">
            </el-popover>
            <font color="blue">{{item.name}}</font>
          </li>

        </ul>
      </div>
      <input id="takepicture" style="display:none" type="file" name="image[]" multiple="multiple" accept="image/gif, image/jpeg, image/jpg, image/png, image/svg" @change="batchUploadFilesChange">
      <div class="layui-word-aux">
        <ul>
          <li>上传必看：</li>
          <li>图片命名为:“馆内地图”；</li>
          <li>只能上传jpg/png/gif类型的图片，且大小不能超过2MB；</li>
        </ul>
        <div class="layui-form-item " >
          <label class="layui-form-label"></label>
          <div class="layui-input-block">
            <button class="layui-btn el-button el-button--primary el-button--small" type="submit" style="padding:10px 75px;font-size:16px;">立即提交</button>
          </div>
        </div>
      </div>

    </form>
    <el-table
        :data="tableData"
        style="font-size: x-large"
        >
      <el-table-column prop="museum_map" label="馆内地图" min-width="50%" align="center">
        <!-- 图片的显示 -->
        <template   slot-scope="scope">
          <img :src="scope.row.museum_map_url"  min-width="300" height="300" />
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      uploadImgageList: [],//批量上传图片集合
      tableData: [],
    }
  },
  created() {
    //created:在模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图。
    this.getList()
  },
  methods:{
    //获取数据库开放表数据
    async getList(){
      const{data:res} = await this.$ajax.get('https://background.lanternmeta.com/api/MuseumMap/index',{params:this.param})
      this.tableData =res
    },
    uploadImage(){
      var takePicture = document.getElementById('takepicture');
      takePicture.click();
    },
    batchUploadFilesChange(e){
      var that = this
      var files=e.target.files
      for( var i in files) {
        const file=files[i]
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function(e) {
          var item = {
            name: file['name'],
            url: this.result,
          }
          //添加到集合
          that.uploadImgageList.push(item);
        }
      }
    }
  }
}
</script>
<style scoped>
.el-pagination{
  margin-top: 1%;
  text-align: center;
}

.el-upload-list--picture{
  min-height:200px;
  max-height:400px;
  overflow:auto;
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  padding:10px;
  margin-top:10px;
}
/*滚动条样式*/
.el-upload-list--picture::-webkit-scrollbar {
  width: 3px;
}
.el-upload-list--picture::-webkit-scrollbar-track {
  background-color:#FCF8E3;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius:2em;
}
.el-upload-list--picture::-webkit-scrollbar-thumb {
  background-color:skyblue;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius:2em;
}
.el-upload-list--picture .el-upload-list__item{height:auto;}
.el-upload-list--picture .el-upload-list__item-thumbnail{float:none;width:50px;height:50px;}
.el-upload-input{width:100px;border-radius:5px;text-indent:5px;}
</style>