<template>


    <!--    表格-->
    <el-card class="box-card">

      <el-input style="width: 30%;margin-left: 1%"
          placeholder="请输入内容"
          v-model="param.key"
                clearable>>
      </el-input>
      <el-button type="primary"icon="el-icon-search"@click="search()" style="margin-left: 0.5%">搜索</el-button>

      <el-button type="primary" style="float: right"@click="add()">添加</el-button>
      <el-table
          :data="tableData"
          style="width: 100%;margin-top: 1%">
        <el-table-column
            prop="create_time"
            label="日期"
            width="180">
        </el-table-column>
        <el-table-column
            prop="title"
            label="标题"
            width="180">
        </el-table-column>
        <el-table-column
            prop="sort"
            label="排序">
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
              <el-button type="primary" size="small"@click="edit(scope.row)">编辑</el-button>
              <el-button type="danger" size="small"@click="del(scope.row.sort)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--    分页-->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[ 2, 5, 10]"
          :page-size="param.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>

      <!-- 添加的表单-->
      <el-dialog
          title="添加表单"
          :visible.sync="addDialogVisible"
          width="30%"
          >
        <!--        <span>这是一段信息</span>-->
        <el-form :model="addform" label-width="80px">
          <el-form-item label="标题">
            <el-input v-model="addform.title"></el-input>
          </el-form-item>
          <el-form-item label="ID">
            <el-input v-model="addform.id"></el-input>
          </el-form-item>
          <el-form-item label="排序">
            <el-input v-model="addform.sort"></el-input>
          </el-form-item>
          <el-form-item label="排序">
          <el-radio v-model="addform.status" :label="1">启用</el-radio>
          <el-radio v-model="addform.status" :label="2">禁用</el-radio>
          </el-form-item>
          <el-form-item label="内容">
            <el-input type="textarea" v-model="addform.content"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addSave()">确 定</el-button>
        </span>
      </el-dialog>

        <!--编辑表单-->
      <el-dialog
          title="编辑表单"
          :visible.sync="editDialogVisible"
          width="30%"
      >
        <!--        <span>这是一段信息</span>-->
        <el-form :model="editform" label-width="80px">
          <el-form-item label="标题">
            <el-input v-model="editform.title"></el-input>
          </el-form-item>
          <el-form-item label="ID">
            <el-input v-model="editform.id"></el-input>
          </el-form-item>
          <el-form-item label="排序">
            <el-input v-model="editform.sort"></el-input>
          </el-form-item>
          <el-form-item label="排序">
          <el-radio v-model="editform.status" :label="1">启用</el-radio>
          <el-radio v-model="editform.status" :label="2">禁用</el-radio>
        </el-form-item>
          <el-form-item label="内容">
            <el-input type="textarea" v-model="editform.content"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editSave()">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>

</template>

<script>
export default {
  data() {
    return {
      //查询页面参数
      param:{
        key:'',
        page:1,
        limit:5
      },
      tableData: [],
      currentPage:1,
      total:0,
      addDialogVisible:false,
      addform:{
        id:'',
        title:'',
        content:'',
        sort:'',
        status:1
      },
      editDialogVisible : false,
      editform:{
        id:'',
        title:'',
        content:'',
        sort:'',
        status:''
      }
    }
  },
  created() {
    //created:在模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图。
        this.getList()
  },
  methods:{
    //添加文章
    async add(){
        this.addDialogVisible=true
      //获取文章序号并赋值
      const{data:res} = await this.$ajax.get('https://background.lanternmeta.com/api/Essay/create',{params:this.param})
      if(res.id==null){
        this.addform.id=1
      }
      if(res.sort==null){
        this.addform.sort=1
      }else{
        this.addform.sort=res.sort+1
        this.addform.id=res.id+1
      }


    },
    async addSave(){
      // 关闭弹窗
      this.addDialogVisible=false
      // 提交数据
      const{data:res} = await this.$ajax.post('https://background.lanternmeta.com/api/Essay/save',this.addform)
      //弹出提示
      if(res.code==1){
        this.$msg.success(res.msg)
        //页面刷新
        this.getList()
        this.addform.title=''
        this.addform.content=''
      }else {
        this.$msg.error(res.msg)
      }
    },
    edit(info){
      this.editDialogVisible =true
      this.editform.title=info.title
      this.editform.sort=info.sort
      this.editform.content=info.content
      this.editform.status=info.status
      this.editform.id=info.id
    },
    async editSave(){
        // 关闭弹窗
      this.editDialogVisible=false
      // 提交数据
      const{data:res} = await this.$ajax.post('https://background.lanternmeta.com/api/Essay/edit',this.editform)
      //弹出提示
      if(res.code==1){
        this.$msg.success(res.msg)
        //页面刷新
        this.getList()
        this.editform.title=''
        this.editform.sort=''
        this.editform.content=''
        this.editform.status=''
        this.editform.id=''
      }else {
        this.$msg.error(res.msg)
      }
    },
    //变化页大小
    handleSizeChange(val){
      this.param.limit= val
      this.getList()

    },
    //选择页数
    handleCurrentChange(val){
      this.param.page= val
      this.getList()
    },
    //查询文章名字
    search(){
      this.getList()
    },
    //获取数据库文章数据
    async getList(){
        const{data:res} = await this.$ajax.get('https://background.lanternmeta.com/api/Essay/index',{params:this.param})
        this.tableData =res.data
        this.total=res.total
    },
    //删除选中选项
    async del(sort){
      const{data:res} = await this.$ajax.get('https://background.lanternmeta.com/api/Essay/deleteessay',{params:{sort:sort}})
      //弹出提示
      if(res.code==1){
          this.$msg.success(res.msg)
        //页面刷新
        this.getList()
      }else {
        this.$msg.error(res.msg)
      }
    }
  }
}
</script>
<style scoped>
.el-pagination{
  margin-top: 1%;
  text-align: center;
}
</style>