import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Essay from "../views/Essay.vue"
import Open from "../views/Open.vue"
import HomePicture from "../views/HomePicture.vue"
import HomeShow from "../views/HomeShow.vue"
import Goods from "../views/Goods.vue"
import Adventure from "../views/Adventure.vue"
import MuseumMap from "../views/MuseumMap.vue"
import MuseumNotice from "../views/MuseumNotice.vue"

Vue.use(VueRouter)

//路由
const routes = [
  {
    path:'/login',
  component: Login
  },
  {
    path:'/',
    component: Home,
    children:[
      {
        path:'/essay',
        component: Essay
      },{
        path:'/open',
        component: Open
      }, {
        path: '/homePicture',
        component: HomePicture
      }, {
        path: '/homeShow',
        component: HomeShow
      }, {
        path: '/goods',
        component: Goods
      }, {
        path: '/museumNotice',
        component: MuseumNotice
      }, {
        path: '/museumMap',
        component: MuseumMap
      }, {
        path: '/adventure',
        component: Adventure
      }
    ]
  }


]
const router = new VueRouter({
  routes
})
//路由守卫：比如说，当点击商城的购物车的时候，需要判断一下是否登录，如果没有登录，就跳转到登录页面，如果登陆了，就跳转到购物车页面，相当于有一个守卫在安检
router.beforeEach((to, from, next) =>{
  //前一个页面是登录页面的话直接跳转home页面
  if(to.path==='/login'){
    next()
  }
  //如果没有存储信息跳转回登录页面
  if(!window.sessionStorage.getItem('token')||window.sessionStorage.getItem('token')==''||window.sessionStorage.getItem('token')==null||window.sessionStorage.getItem('token')==undefined)
  {
    next('/login')
  }
  //跳转home页面
  next()
})
export default router
